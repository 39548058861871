import React, { createContext, useContext, useState, useEffect } from 'react';
import en from './en.json';
import ru from './ru.json';

const TranslationContext = createContext();

const translations = {
    en,
    ru
};

export const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState('ru');

    const t = (key) => {
        return translations[language][key] || key;
    };

    const changeLanguage = (lang) => {
        if (translations[lang]) {
            setLanguage(lang);
        } else {
            setLanguage('en');
            console.warn(`Language ${lang} is not supported.`);
        }
    };

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage && translations[storedLanguage]) {
            setLanguage(storedLanguage);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    return (
        <TranslationContext.Provider value={{ t, changeLanguage }}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = () => {
    return useContext(TranslationContext);
};

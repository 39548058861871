import React, {useEffect, useState} from 'react';
import {useTranslation} from "../lang/TranslationContext";
import axios from "axios";
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";

const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log(1);
      alert('Success')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  

const Modals = ({tg, handleOverlayClick, activeModal, currentBalance, openModal, currentWallet, fetchHistory}) => {
    const  payWallet = "UQA0PmZrYkW9VPkt8XRCr8vYoVFELwTXIfNS9hZmT-JLTtoV"
    const {t} = useTranslation();
    const wallet = useTonWallet();
    const [tonConnectUi, connected ] = useTonConnectUI();
    const [amount, setAmount] = useState(10);
    const [out, setOut] = useState(1);

    const setWallet = async (walletAddress) => {
            try {
            const initData = tg && tg.initData ? tg.initData : null;
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/setWallet.php`, {
                            method: 'POST',
                            headers: {
                                    'Authorization': initData || '',
                                    'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({'wallet': walletAddress}),
                    });

                    const result = await response.json();
                    if (result.status === 'success') {
                   
                    } else {
                        alert(result.message)
                    }
            } catch (error) {
                console.log(error)
            }
    };

    useEffect(()=>{
        if (wallet) {
            if(currentWallet !== false) {
                const walletAddress = wallet.account.address; 
                if(walletAddress !== currentWallet) {
                    setWallet(walletAddress)
                }
            }
           
        }
    }, [wallet, currentWallet])

    const sendTransaction = async (amountInTon) => {
        if (!connected || !wallet) {
          console.error('Wallet is not connected');
          return;
        }
    
        try {
          const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 3600, // Время действия транзакции (в секундах)
            messages: [
              {
                address: payWallet, // Замените на адрес получателя
                amount: (amountInTon * 1e9).toString(), // Конвертация TON в нанотоны
                payload: '', // Дополнительные данные (необязательно)
              },
            ],
          };
    
          // Отправка транзакции
          const result = await tonConnectUi.sendTransaction(transaction);
          console.log('Transaction sent:', result);
        } catch (error) {
          console.error('Failed to send transaction:', error);
        }
      };

    useEffect(() => {
        if (connected) {
            console.log('Connected wallet:', wallet);
            const walletAddress = wallet?.account?.address;
            console.log('Wallet Address:', walletAddress);
            // Здесь вы можете сохранить или использовать адрес кошелька
        }
        console.log('wallet', wallet)
        console.log('connected', connected)
    }, [connected, wallet]);

    const withdrawMoney = async () => {
        try {
          const initData = tg && tg.initData ? tg.initData : null;
                const response = await fetch(`${process.env.REACT_APP_API_URL}/createWithdraw.php`, {
                        method: 'POST',
                        headers: {
                                'Authorization': initData || '',
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                          'currency_id': 3, 
                          'amount' : out
                        }),
                });

                const result = await response.json();
                if (result.status === 'success') {
                  alert(result.message)
                  fetchHistory()
                } else {
                     alert(result.message)
                }
        } catch (error) {
                console.log(error)
        }
};


    return (
        <>
            <div className={`overlay fixed-block ${activeModal ? 'active' : ''}`} data-overlay
                 onClick={handleOverlayClick}>
                

                <div className={`modal ${activeModal === 'wallet' ? 'active' : ''}`} data-popup="wallet"
                     style={activeModal === 'wallet' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <div className="modal__top">
                        <span className="modal__title">Укажите кошелек TON</span>
                        <p>Эта сумма будет отправлена на ваш TON кошелек</p>

                        <div className="main-info">
                            <div className="main-info__box">
                                <div className="main-info__inner">
                                    <div className="main-info__icon">
                                        <img width='47' height='47' src='img/index/ton.png' alt='image'/>
                                    </div>
                                    <span className="main-info__title">Баланс:</span>
                                    <span className="main-info__value">
                                      <b>{currentBalance}</b> TON
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal__box">
                        <form className="main-form withdraw">
                            <div className="main-form__inner">
                                <span className="main-form__balance">Amount:</span>
                                <label htmlFor="coin" className="main-form__label">
                                    <input value={out}  onChange={(event) => setOut(event.target.value)}  type="number" min="100" name="coin" id="coin" className="main-form__input"
                                           placeholder="10"/>

                                    <span className="main-form__icon">
                            <img width="24" height="24" src="./img/index/ton.png" alt="icon"/>
                        </span>
                                </label>
                                <span className="main-form__text">Минимальная сумма вывода: 0,35 TON</span>
                            </div>

                            <div className="main-form__btns">
                            {wallet ? (<>
                                <button onClick={()=>{withdrawMoney()}} type="button" className="blue-btn">Вывести средства
                                    <svg width='24' height='24'>
                                        <use href='img/sprite/sprite.svg#bolt'></use>
                                    </svg>
                                </button>
                            </>
) : (<>
                            <p>Подключите ваш TON кошелек для выплаты</p>
                            <button type="button" onClick={() => tonConnectUi.openModal()} className="blue-btn" data-btn-inner="wallet">Подключить кошелек
                                <img width='24' height='24' src='img/index/ton.png' alt='image'/>
                            </button>
</>)}
                                
                                <a className="transparent-btn close">Cancel</a>
                            </div>

                        </form>
                    </div>
                </div>

                <div className={`modal ${activeModal === 'connect' ? 'active' : ''}`} data-popup="connect"
                     style={activeModal === 'connect' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <div className="modal__top modal__top--mode">
                        <span className="modal__title">Детали заказа</span>
                        <ul className="modal__list">
                            <li className="modal__item">
                                <span className="modal__text">Мощность: <b>{Number(amount * 10000).toFixed(2)} GH/s ⚡</b></span>
                            </li>
                            <li className="modal__item">
                                <span className="modal__text">Период аренды: <b>30 days</b></span>
                            </li>
                            <li className="modal__item">
                                <span className="modal__text">Общий доход: <b>~{Number(amount * 1.5).toFixed(4)} TON</b></span>
                            </li>
                            <li className="modal__item">
                                <span className="modal__text">Дневной доход:<b>~{Number(amount * 1.5 /30).toFixed(4)} TON</b></span>
                            </li>
                            <li className="modal__item">
                                <span className="modal__text">Цена: <b> {amount} TON</b></span>
                            </li>
                        </ul>

                        <div className="modal__wrapp">
                            <span className="modal__title">Оплата</span>
                            {wallet ? (<>
  <button type="button" onClick={() => {sendTransaction(amount)}} className="blue-btn" data-btn-inner="wallet">Оплатить
                                <img width='24' height='24' src='img/index/ton.png' alt='image'/>
                            </button>
                            <span className="modal__pretitle">или</span>

                            <p>Отправьте на указанный адресс депозита  с вашего кошелька .</p>
                            </>
) : (<>

                            <p>Подключите ваш TON кошелек для оплаты заказа</p>
                            <button type="button" onClick={() => tonConnectUi.openModal()} className="blue-btn" data-btn-inner="wallet">Подключить кошелек
                                <img width='24' height='24' src='img/index/ton.png' alt='image'/>
                            </button>
</>)}

                            <a className="transparent-btn close">Cancel</a>

                           
                        </div>
                    </div>

                    {wallet ? (<form className="main-form withdraw">
                        <div className="main-form__box">
                            <div className="main-form__qr">
                                <img width='182' height='184' src='img/qr.png' alt='image'/>
                            </div>

                            <span className="main-form__value"><b>{amount}</b> TON</span>

                            <label className="main-form__label" htmlFor="address_1" data-parent-copy>
                                <input onClick={()=>{copyText(payWallet)}} readOnly type="text" name="address_1" id="address_1" className="main-form__input"
                                       value={payWallet}/>

                                <button type="button"  onClick={()=>{copyText(payWallet)}} className="copy-btn" data-copy>
                                    <img width='24' height='24' src='img/sprite/copy.svg' alt='image'/>
                                </button>
                            </label>
                        </div>
                    </form>) : <></> }
                    
                </div>

                <div className={`modal ${activeModal === 'buy' ? 'active' : ''}`} data-popup="buy"
                     style={activeModal === 'buy' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <div className="modal__top">
                        <span className="modal__title">Buy boost</span>
                        <p>Здесь вы можете арендовать мощность майнинга на 30 дней.</p>
                        <p>Доходность инвестиции составляет 5%/день или 150% за 30 дней.</p>
                    </div>

                    <div className="modal__box">
                        <form className="main-form withdraw">
                            <div className="main-form__inner">
                                <span className="main-form__balance">Amount:</span>
                                <label htmlFor="coin" className="main-form__label">
                                    <input type="number" value={amount} onChange={(event) => setAmount(event.target.value)}  min="1" name="coin" id="coin" className="main-form__input"
                                           placeholder="10"/>

                                    <span className="main-form__icon">
                            <img width="24" height="24" src="./img/index/ton.png" alt="icon"/>
                        </span>
                                </label>
                                <span className="main-form__text">Min amount: 1 TON</span>
                            </div>

                            <div className="main-form__btns">
                                <button type="button" onClick={()=> {openModal('connect')}} className="blue-btn">Buy Th/s
                                    <svg width='24' height='24'>
                                        <use href='img/sprite/sprite.svg#bolt'></use>
                                    </svg>
                                </button>
                                <a className="transparent-btn close">Cancel</a>
                            </div>

                        </form>
                    </div>
                </div>


            </div>
        </>
    );
};

export default Modals;

import React from 'react';

const Tasks = ({tg}) => {

    return (
        <>
            <main>

                <section className="boost-section">
                    <div className="container">
                        <div className="boost-section__box">
                            <div className="main-top">
                                <h1 className="title">
                                    Tasks
                                    <svg width='24' height='24'>
                                        <use href='img/sprite/sprite.svg#i'></use>
                                    </svg>
                                </h1>
                            </div>

                            <div className="personal-card" style={{marginTop: "50px"}}>
                                <span className="personal-card__title">Coming soon...</span>
                            </div>

                            

                        </div>
                    </div>
                </section>

            </main>
        </>
    );
};

export default Tasks;

import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import Mining from '../components/Mining';
import {useTranslation} from "../lang/TranslationContext";

const Home = ({tg, openModal, setCurrentBalance, setCurrentWallet}) => {

  
const calculateSpeed = (power, percentMine = 100) => {
  let budget = 0.0001 * power;
  console.log('tons', budget)
  let proc = 0.05;
  let proc_sec = proc / (86400);
  console.log('proc_sec', proc_sec)
  console.log('proc_day', proc_sec * 86400)
  let zarabotok = budget * proc_sec;

  return zarabotok
     
}

    const { t } = useTranslation();
    const [data, setData] = useState([]);

    const [activeModal, setActiveModal] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);
  
  const [secondPlus, setSecondPlus] = useState(0)

  const [speed, setSpeed] = useState(0)

  useEffect(()=> {
    if(data && data.power) {
      setSpeed(calculateSpeed(Number(data.power), Number(data.mining["mining_3"])))
    }
      
  }, [data]);

  const [nowMoney, setNowMoney] = useState(0)
    useEffect(() => {
      console.log('speed', speed)
        const incrementMoney = () => {
            setNowMoney(prevMoney => Number(prevMoney) + Number(speed) / 10 );
        };

        const intervalId = setInterval(incrementMoney, 100);

        return () => clearInterval(intervalId);
    }, [speed]); 

    const fetchData = async () => {
      const initData = tg && tg.initData ? tg.initData : null;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}`, 
        {
          headers: {
            'Authorization': initData
        },
      }
      )
      .then(response => {
        setData(response.data);
        setNowMoney(response.data.user['balance_3'])
        setCurrentBalance(response.data.user['balance_3'])
        setCurrentWallet(response.data.user['wallet'])
      }).catch(error => {
        console.log('Error fetching data:', error);
      });

    };

    useEffect(() => {
      if(tg) {
        fetchData();
      }
    }, [tg]);

    return (
        <>
            <main>
                <section className="miners-section">
                    <div className="container">
                        <div className="miners-section__box">
                            <div className="main-info">
                                <div className="main-info__box">
                                    <div className="main-info__inner">
                                        <div className="main-info__icon">
                                            <img width='47' height='47' src='img/index/ton2.png' alt='image'/>
                                        </div>
                                        <span className="main-info__title">{t('Balance')}:</span>
                                        <span className="main-info__value" >
                                          <b>{data && data.user && data.user['balance_3']}</b> TON
                                        </span>
                                    </div>
                                    <button type="button" onClick={()=>{openModal('wallet')}} className="transparent-btn">{t('Withdraw')}</button>
                                </div>
                            </div>

                            <div className="miners-section__video">
                                <video width='278' height='278' playsInline muted autoPlay loop>
                                    <source src="./img/comp.webm" type="video/webm"/>
                                        <source src="./img/comp.mp4" type="video/mp4"/>
                                </video>
                            </div>

                            <div className="miners-section__inner">
                                <span className="miners-section__value"><b  style={{fontFamily: "Verdana"}}>{Number(nowMoney).toFixed(9)}</b> TON</span>
                                <span className="miners-section__text">⚡ <b>{data && data.power}</b> Gh/s</span>

                                <div className="miners-section__btns">
                                    <button onClick={()=>{openModal('buy')}} className="blue-btn" data-btn-modal="buy">{t('Buy')} Th/s
                                        <svg width='24' height='24'>
                                            <use href='img/sprite/sprite.svg#bolt'></use>
                                        </svg>
                                    </button>
                                    <button onClick={()=>{openModal('wallet')}} className="transparent-btn" data-btn-modal="withdraw">{t('Withdraw')}</button>
                                </div>
                            </div>
                        </div>
                        </div>


            </section>
        </main>
</>
);
};

export default Home;
